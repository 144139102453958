import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseJson } from '../models/model';

const httpOptions = {
  headers: new HttpHeaders({ 'Accept': 'application/json' })
};


@Injectable({ providedIn: 'root' })
export class BaseService {

  constructor(protected http: HttpClient, protected _zone?: NgZone) { }

  public get<T>(url: string, requestBody?: any): Observable<ResponseJson<T>> {// eslint-disable-line @typescript-eslint/no-explicit-any
    return this.http.get<ResponseJson<T>>(url, { headers: httpOptions.headers, params: requestBody }).pipe(
      catchError((error) => this.handleError(error))// then handle the error
    );
  }

  public post<T>(url: string, requestBody?: any | null, responseType?: any): Observable<ResponseJson<T>> { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.http.post<ResponseJson<T>>(url, requestBody, { headers: httpOptions.headers, responseType }).pipe(
      catchError((error) => this.handleError(error))// then handle the error
    );
  }

  public put<T>(url: string, requestBody: any | null): Observable<ResponseJson<T>> { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.http.put<ResponseJson<T>>(url, requestBody, { headers: httpOptions.headers }).pipe(
      catchError((error) => this.handleError(error))// then handle the error
    );
  }

  public delete<T>(url: string, requestBody?: any | null): Observable<ResponseJson<T>> { // eslint-disable-line @typescript-eslint/no-explicit-any
    return this.http.delete<ResponseJson<T>>(url, { headers: httpOptions.headers, body: requestBody }).pipe(
      catchError((error) => this.handleError(error))// then handle the error
    );
  }

  public stream<T>(url: string, queryParams?: any): Observable<T> {
    return new Observable(observer => {
      // Construct the full URL with query params if provided
      const params = new URLSearchParams(queryParams);
      const token = localStorage.getItem('token') as string;
      params.append('token', token);
      const queryString = params.toString();
      const eventSourceUrl = params ? `${url}?${params}` : url;

      const eventSource = new EventSource(eventSourceUrl);

      eventSource.onmessage = (event) => {
        this._zone?.run(() => {
          observer.next(JSON.parse(event.data) as T);
        });
      };

      eventSource.onerror = (error) => {
        this._zone?.run(() => {
          observer.error(error);
        });
        eventSource.close();
      };

      // Add event listener for closing event stream on 'complete'
      eventSource.addEventListener('complete', () => {
        eventSource.close();
        observer.complete();
      });

      // Return a cleanup function to close the event source
      return () => {
        eventSource.close();
      };
    });
  }

  protected handleError(error: HttpErrorResponse): Observable<any> { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error);
    } else {
      // The backend returned an unsuccessful response code.
      console.error(JSON.stringify(error));
      console.error(`Backend returned code ${error.status}, body was: ${error.error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error.error);
  }

}
